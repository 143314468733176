import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ChapterNavigationButton } from './ChapterNavigationButton';
import { ChapterNavigationArticles } from './ChapterNavigationArticles';

const ChapterNavigation = ({ chapters }) => {
  const articlesListRef = useRef(null);
  const [openChapterNumber, setChapterNumber] = useState('1');

  const toggleChapterItem = (chapterNumber) => {
    setChapterNumber(openChapterNumber === chapterNumber ? '1' : chapterNumber);
  };

  return (
    <div className="ch-nav inverted">
      <nav className="page__frame">
        <ul className="ch-nav__list">
          {chapters.map((chapter) => (
            <li
              className={classNames('ch-nav__item', {
                open: openChapterNumber === chapter.number,
              })}
              key={chapter.number}
              aria-expanded={openChapterNumber === chapter.number}
            >
              <ChapterNavigationButton
                chapterNumber={chapter.number}
                chapterTitle={chapter.name}
                toggleChapterItem={toggleChapterItem}
              />
              <ChapterNavigationArticles
                articlesListRef={articlesListRef}
                chapterArticles={chapter.articles}
              />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

ChapterNavigation.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      chapter: PropTypes.shape({
        chapterNumber: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        articles: PropTypes.array.isRequired,
      }),
    })
  ).isRequired,
};

export { ChapterNavigation };
