import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ChapterNavigationArticles = ({ articlesListRef, chapterArticles }) =>
  chapterArticles.length > 0 && (
    <div className="ch-nav-articles">
      <ul className="ch-nav-articles__list" ref={articlesListRef}>
        {chapterArticles.map((article) => (
          <li className="ch-nav-articles__item" key={article.id}>
            <Link className="ch-nav-articles__link" to={article.slug}>
              {article.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );

// Temp placement untill find a better place for it
global.Element = typeof Element === 'undefined' ? function() {} : Element; // eslint-disable-line

ChapterNavigationArticles.propTypes = {
  chapterArticles: PropTypes.arrayOf(
    PropTypes.shape({
      article: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  articlesListRef: PropTypes.shape(),
};

export { ChapterNavigationArticles };
