import PropTypes from 'prop-types';
import React from 'react';
import { graphql, Link } from 'gatsby';

import './scss/styles.scss';
import { Layout } from 'layout';
import { secretSourceChapters } from 'utilities';
import AurimasAdomaviciusProfileImage from 'uploads/aurimas-profile-face.jpg';
import DevbridgeLogo from 'uploads/db.png';
import { HeroBannerImage } from 'components/heroBanner';
import { Section } from 'components/section';
import { THOUGHT_LEADERS_AURIMAS_ROUTE } from 'shared/routes';
import { ChapterNavigation, AboutSection } from './components';

const SecretSourceLanding = ({ data }) => {
  const { markdownRemark } = data;
  const chapters = secretSourceChapters();
  const firstChapterLink = chapters[0].articles[0].slug;

  const authorImage = AurimasAdomaviciusProfileImage;
  const authorImageText = 'Aurimas Adomavicius';

  const companyImage = DevbridgeLogo;
  const companyImageText = 'Devbridge logotype';

  const renderControls = () => (
    <Link className="ss-landing-hero__link btn" to={firstChapterLink}>
      Read now
    </Link>
  );

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      className="ss-landing"
    >
      <HeroBannerImage
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.frontmatter.landingPromoDescription}
        renderControls={firstChapterLink && renderControls}
        heroImage={
          markdownRemark.frontmatter.landingPromoImage &&
          markdownRemark.frontmatter.landingPromoImage.children[0].fluid
        }
        heroImageText={markdownRemark.frontmatter.landingPromoImageText}
        noGradient
        className="ss-landing-hero"
      />
      <Section
        title={markdownRemark.frontmatter.chapterIntroTitle}
        description={markdownRemark.frontmatter.chapterIntroDescription}
        className="ss-landing-section inverted"
      />
      <ChapterNavigation chapters={chapters} />
      <AboutSection
        imageText={authorImageText}
        image={authorImage}
        description={markdownRemark.frontmatter.authorDescription}
        linkText="Read more"
        link={THOUGHT_LEADERS_AURIMAS_ROUTE}
      />
      <AboutSection
        image={companyImage}
        imageText={companyImageText}
        description={markdownRemark.frontmatter.companyDescription}
        linkText="Read more"
        link="https://www.devbridge.com/"
      />
    </Layout>
  );
};

SecretSourceLanding.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const secretSourceLandingQuery = graphql`
  query SecretSource($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        title
        author
        landingPromoDescription
        landingPromoImage {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 335, maxHeight: 387) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        landingPromoImageText
        chapterIntroTitle
        chapterIntroDescription
        companyDescription
        authorDescription
      }
    }
  }
`;

export default SecretSourceLanding;
