import React from 'react';
import PropTypes from 'prop-types';

import { CMSLink } from 'components/cmsLink';

const AboutSection = ({ description, image, imageText, link, linkText }) => (
  <section className="ss-landing-about">
    <div className="page__frame">
      <div className="ss-landing-about__content">
        <figure className="ss-landing-about__figure">
          <img
            src={image}
            alt={imageText}
            className="ss-landing-about__image"
          />
        </figure>
        <div className="ss-landing-about__wrapper">
          {description && description !== '' && (
            <div
              className="ss-landing-about__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {link && linkText && (
            <CMSLink linkUrl={link} className="ss-landing-about__link">
              {linkText}
            </CMSLink>
          )}
        </div>
      </div>
    </div>
  </section>
);

AboutSection.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageText: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  link: PropTypes.string,
};

export { AboutSection };
