import React from 'react';
import PropTypes from 'prop-types';

const ChapterNavigationButton = ({
  toggleChapterItem,
  chapterNumber,
  chapterTitle,
}) => {
  const formatChapterNumber = (number) =>
    number < 10 ? `0${number}.` : `${number}.`;
  return (
    <button
      type="button"
      className="ch-nav-button"
      onClick={() => toggleChapterItem(chapterNumber)}
    >
      <div className="ch-nav-button__content-wrapper">
        <div className="ch-nav-button__number">
          {formatChapterNumber(chapterNumber)}
        </div>
        <div className="ch-nav-button__title">{chapterTitle}</div>
      </div>
    </button>
  );
};

ChapterNavigationButton.propTypes = {
  chapterTitle: PropTypes.string.isRequired,
  chapterNumber: PropTypes.number.isRequired,
  toggleChapterItem: PropTypes.func.isRequired,
};

export { ChapterNavigationButton };
